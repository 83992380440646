import React from 'react';
import NavbarThree from "../components/App/NavbarThree"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import MainBanner from '../components/SolutionTalentAcquisition/MainBanner';
import Widgetb from '../components/SolutionTalentAcquisition/Pa2widgetb'

import OurSolutions from '../components/SolutionTalentAcquisition/OurSolutions';
import AboutArea from '../components/SolutionTalentAcquisition/AboutArea';
import OurMission from '../components/SolutionTalentAcquisition/OurMission';
import Funfacts from '../components/SolutionTalentAcquisition/Funfacts';
import UpcomingBootcamps from '../components/SolutionTalentAcquisition/UpcomingBootcamps';
import HowItWork from '../components/SolutionTalentAcquisition/HowItWork';
import RecentProjects from '../components/SolutionTalentAcquisition/RecentProjects';
import TeamMembers from '../components/SolutionTalentAcquisition/TeamMembers';
import Testimonials from '../components/SolutionTalentAcquisition/Testimonials';
import Partner from '../components/SolutionTalentAcquisition/Partner';
import BlogPost from '../components/SolutionTalentAcquisition/BlogPost';
import StartProjectTwo from '../components/Common/StartProjectTwo';


const home = ({location}) => {
    return (
        <Layout path={location.pathname}>
            <NavbarThree />
            <MainBanner />
                <Widgetb />
                <OurSolutions />
            <AboutArea />
            <OurMission />
            <Funfacts />
            <UpcomingBootcamps />
            <HowItWork />
            <RecentProjects />
            <TeamMembers />
            <Testimonials />
            <Partner />
            <BlogPost />
            <StartProjectTwo />
            <Footer />
        </Layout>
    );
}

export default home;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;